@import 'variables.scss';

:root {
    --primary-color: $primary-color;
}

/* 
    layout
*/

@import '../layout/Header/Header.scss';
@import '../layout/Main/Main.scss';
@import '../layout/Footer/Footer.scss';


// modules

@import '../modules/CommandBar/CommandBar.scss';
@import '../modules/Gallery/Gallery.scss';


/* 
    contaiers
*/

//  @import '../containers/Lightbox/Lightbox.scss';
@import '../containers/Wrapper/Wrapper.scss';


/* 
    components
*/

@import '../components/Button/Button.scss';
@import '../components/Faceholder/Faceholder.scss';
@import '../components/Input/Input.scss';
@import '../components/Link/Link.scss';
@import '../components/Loading/Loading.scss';
@import '../components/Skeleton/Skeleton.scss';
@import '../components/Spinner/Spinner.scss';


main {
    margin-top: 2rem;
}

.Leadspace {
    &-container {
        padding: 2rem 0;
        width: 50%;
        max-width: 30rem;
    }
    min-height: 50vh;
    color: $blue-20;
    background-color: $blue-60; // $background;
}
  
  
.About {
    min-height: 50vh; // 75vh;
    background: $gray-100;
    padding: 2rem 0;
    .content {
        width: 50%;
        max-width: 30rem;
    }
}