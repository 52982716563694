@import '../../styles/variables.scss';

$gutter: 1px;

.Gallery-module {
  min-height: 62.5vw;
}
.Gallery {
  margin-top: 1rem;

  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
.Card {
  padding: $gutter;
  &.aspect-ratio-flex {
    flex-grow: 1;
    min-width: calc(100% / 4);
  }
  &.aspect-ratio-1-1 {
    max-width: calc(100% / 8);
    flex: 0 0 calc(100% / 8);

    @media (max-width: 166rem) {
      max-width: calc(100% / 8);
      flex: 0 0 calc(100% / 8);
    }
    @media (max-width: 133rem) {
      max-width: calc(100% / 6);
      flex: 0 0 calc(100% / 6);
    }
    @media (max-width: 99rem) {
      max-width: calc(100% / 6);
      flex: 0 0 calc(100% / 6);
    }
    @media (max-width: 82rem) {
      max-width: calc(100% / 4);
      flex: 0 0 calc(100% / 4);
    }
    @media (max-width: 66rem) {
      max-width: calc(100% / 3);
      flex: 0 0 calc(100% / 3);
    }
    @media (max-width: 42rem) {
      max-width: calc(100% / 2);
      flex: 0 0 calc(100% / 2);
    }
    @media (max-width: 33rem) {
      max-width: calc(100%);
      flex: 0 0 calc(100%);
    }
  }
}
.List-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: -1rem; // $gutter;
  margin-bottom: 0;
}
.List {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
  .Image img {
    transition: 300ms;
    filter: brightness(70%);
  }
  .Image:hover img {
    filter: brightness(100%);
  }
}

.Faceholder {
  position: relative;
  padding-bottom: 100%;
  width: 100%;
  height: 100%;
}
.Image-placeholder {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}