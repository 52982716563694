@import '../../styles/variables.scss';

.Input {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background;
  color: $gray-30;
  user-select: none;
}