@import '../../styles/variables.scss';

.Spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.Spinner--xlarge {
    width: 2rem;
    height: 2rem;
  }
  &.Spinner--large {
    width: 1.75rem;
    height: 1.75rem;
  }
  &.Spinner--medium {
    width: 1.25rem;
    height: 1.25rem;
  }
  &.Spinner--small {
    width: 1rem;
    height: 1rem;
  }
  &.Spinner--xsmall {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.Spinner-circle {
  box-sizing: border-box;
  animation-name: spinner;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
  width: inherit;
  height: inherit;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: $primary-color currentColor currentColor; //$gray-20
  border-image: initial;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


