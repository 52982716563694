@import '../../styles/variables.scss';

.Header {

  color: $blue-20;
  height: 5rem;
  // background-color: $blue-90; // $background;
  // border-bottom: 1px solid $gray-80;
  
  .Wrapper {
    display: flex;
    height: 100%;
  }

  .Header-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
  }

  .Heading {
    display: flex;
    flex-wrap: wrap;

    .logo {
      font-weight: 600;
      line-height: 1.2;
      padding: 1rem 0.5rem 1rem 0;
      margin: 0;
    }
    .slogan {
      font-size: 1rem;
      // @media (max-width: 66rem) {
      //   font-size: 1.5rem;
      // }
      margin-top: 1rem;
      // margin-bottom: 10%;
    }
  }

}