@import '../../styles/variables.scss';

.BumperSection {
  display: flex;
  padding: 1rem;
  background-color: $gray-80;
  height: 100vh;
  max-height: 16rem;
  min-height: 16rem;
  min-width: calc(100% / 4);
  transition: max-height 600ms ease, font-size 150ms ease,;
  font-size: 125%;

  .glyph {
    font-size: 1.5rem;
    font-weight: 300;
  }

  &.hover {
    // min-height: 50vh;
    max-height: 24rem;
    font-size: 150%;
    margin-bottom: 0 !important;
    position: sticky;
    z-index: 2000;
    cursor: pointer;
  }
  &:hover {
    background-color: #474747; // $gray-80-hover;
  }
  &:active {
    background-color: $gray-60;
  }
  h6 {
    margin: 1rem 0;
  }
}