@import '../../styles/variables.scss';

.ff--skeleton__placeholder {
    &--small {
      height: 6rem;
      width: 6rem;
    }

    &--medium {
      height: 10rem;
      width: 10rem;
    }

    &--large {
      height: 16rem;
      width: 16rem;
    }

    &--flex {
      height: 100%;
      width: 100%;
    }
    position: relative;
    padding: 0;
    border: none;
    //background: $gray-90;
    box-shadow: none;
    pointer-events: none;
    width: 6.25rem;
    height: 6.25rem;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: 1500ms ease-in-out skeleton infinite;
      background: $gray-80;
      content: "";
      will-change: transform-origin,transform,opacity;
  }
} 

@keyframes skeleton {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}