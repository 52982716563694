@import '../../styles/variables.scss';


.spbx--button {
  font-size: 1rem; //0.875rem
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  max-width: 20rem;
  min-height: 3rem;
  margin: 0px;
  padding: 0.875rem;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  border-radius: 0px;
  outline: 2px solid transparent;
  outline-offset: -3px;
  cursor: pointer;
  transition: all 150ms cubic-bezier(0, 0, 0.38, 0.9) 0s;

  border-color: rgba(0,0,0,0);
  border-style: solid;
  border-width: 1px;

  &__icon {
    position: absolute;
    flex-shrink: 0;
    top: auto;
    height: 1.25rem;
    width: 1.25rem;
    right: 1rem;
  }
  
  &--primary,
  &--secondary,
  &--danger,
  &--warning,
  &--ghost:not([class*="--icon-only"]):has([class*="--icon"])
  {
    padding: calc(0.875rem - 3px) 63px calc(0.875rem - 3px) 15px;
  }

  &--default {
    color: $white;
    background-color: $gray-70;
    &:hover {
      background-color: $gray-60;
    }
    &:active {
      background-color: $gray-50;
    }
  }

  &--primary {
    color: $white;
    background-color: $primary-color;
    &:hover {
      background-color: darken($primary-color, 10%);
    }
    &:active {
      background-color: darken($primary-color, 20%);
    }
  }

  &--danger {
    color: $white;
    background-color: $danger-color;
  }

  &--warning {
    color: $gray-100;
    background-color: $warning-color;
  }

  &--secondary {
    color: $white;
    background-color: $gray-80;
    &:hover {
      background-color: $gray-70;
    }
    &:active {
      background-color: $gray-60;
    }
  }
  &--ghost {
    color: $white;
    background-color: transparent;
    &:hover {
      background-color: rgba($gray-50, 0.12);
    }
    &:active {
      background-color: rgba($gray-50, 0.32);
    }
  }
  &--white {
    color: $gray-100;
    background-color: $white;
    &:hover {
      background-color: $gray-20;
    }
    &:active {
      background-color: $gray-30;
    }
  }
  
  &--green {
    color: $white;
    background-color: $green-color;
  }
  &--orange {
    color: $white;
    background-color: $orange-color;
  }
  &--custom {
    color: $white;
    background: none;
  }

  &--disabled, &:disabled {
    &[class*="--ghost"] {
      color: $gray-60;
      background-color: transparent;
    }
    color: $gray-30;
    background-color: $gray-60;
    cursor: not-allowed;
  }
  &:focus {
    outline-color: currentColor;
  }
}


.spbx--button--icon-only {
  min-width: 3rem;
  padding: 0.875rem;
  // padding-right: 0.9375rem;
  // padding-left: 0.9375rem;
  .spbx--button__icon {
    position: static;

  }
}